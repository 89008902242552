import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import ToolboxLayout from '../../layouts/toolboxLayout';
import SecuritySettingsContent from 'components/Page/Toolbox/SecuritySettingsContent';
import { updateUser } from 'services/userService';
import userToolBoxJwt from 'hooks/useToolboxStorage';
import UserContext from 'utils/UserContext';

const ToolboxSecuritySettings = ({ data }) => {
  const { seo: seoContent } = data.contentfulContentHub;
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isWaiting, setIsWaiting] = useState(true);

  const saveUser = async ({ email, password, question, answer }, openLoginModal) => {
    setIsWaiting(true);

    const jwt = userToolBoxJwt.get();
    try {
      await updateUser(jwt, { email, password, question, answer });
      setSuccessMessage('Account Security Settings saved.');
      setUserInfo(userData);
    } catch (e) {
      if (e?.response?.data?.error?.toLowerCase() === 'jwt is expired') {
        openLoginModal();
        setErrorMessage('Please log-in and save again.');
      } else {
        setErrorMessage(e?.response?.data);
      }
    }
    setIsWaiting(false);
  };

  return (
    <ToolboxLayout contentfulSeo={seoContent} footer={null} header={null} layout="toolbox">
      <UserContext.Consumer>
        {({ userInfo, setUserInfo, openLoginModal }) => {
          return (
            userInfo && (
              <SecuritySettingsContent
                isWaiting={false}
                userInfo={userInfo}
                onSave={(data) => saveUser(data, setUserInfo, openLoginModal)}
                errorMessage={errorMessage}
                successMessage={successMessage}
              />
            )
          );
        }}
      </UserContext.Consumer>
    </ToolboxLayout>
  );
};

export default ToolboxSecuritySettings;

export const ToolboxSecuritySettingsPage = graphql`
  query ToolboxSecuritySettingsPageQuery {
    contentfulContentHub(internalName: { eq: "Toolbox" }) {
      seo {
        ...Seo
      }
    }
  }
`;
