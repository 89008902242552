import styled from 'styled-components';

export const StyledSettingsMenu = styled.div`
  font-weight: var(--font-weight-500);
  width: 269px;
  color: white;
  padding: 20px 20px 10px 20px;
  background-color: var(--darkest);
  border-radius: 4px;

  > h6 {
    padding-bottom: 0px;
    padding-top: 10px;
    font-weight: var(--font-weight-500);
    a {
      color: white;
    }
    &.selected {
      a {
        color: var(--titan-blue-3);
      }
      .settings-menu-icon > div {
        background-color: var(--titan-blue-3);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
  > h6:last-child {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 20px;
    margin-top: 20px;
  }
  .settings-menu-text {
    line-height: var(--line-height-150);
  }
  .settings-menu-icon > div {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 100%;
    white-space: nowrap;
  }
`;
