import React from 'react';
import { withFormik } from 'formik';
import FormControl from 'components/Core/FormControl';
import { Col, Row } from 'components/Core/Grid';
import Text from 'components/Core/Text';
import Button from 'components/Core/Button';
import Waiting from 'components/Page/Toolbox/Waiting';
import FormTextField from 'components/Page/Toolbox/FormTextField';
import passwordSchema from 'components/Page/Toolbox/Schemas/password';
import SecurityQuestionSelect from 'components/Page/Toolbox/SecurityQuestionSelect';
import { getFormValid } from 'utils/formUtils';
import SettingsMenu from '../SettingsMenu';
import { ToolboxSettingsSectionLayout, FormRow, FormCol, RightCol } from './styles';

const SecuritySettingsContent = ({
  isWaiting,
  userInfo = {},
  onSave,
  errorMessage,
  successMessage,
  title = 'Account Security',
  ...otherProps
}) => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    submitCount,
    errors,
    isValid,
    touched,
  } = otherProps;

  const formValid = getFormValid({ errors, submitCount });

  return (
    <ToolboxSettingsSectionLayout>
      <Row>
        <Col md={6}>
          <SettingsMenu selected={title} userInfo={userInfo} />
        </Col>
        <Col md={18}>
          {isWaiting && <Waiting />}
          {!isWaiting && (
            <>
              <Text type="h5" color="white">
                {title}
              </Text>
              <div>
                <FormRow>
                  <FormCol>
                    <FormTextField
                      id="password"
                      placeholderText="Password"
                      values={values}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      formValid={formValid}
                      formErrors={errors}
                    />
                  </FormCol>
                  <FormCol>
                    <FormControl fullWidth>
                      <FormTextField
                        id="password2"
                        placeholderText="Verify Password"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="password"
                        formValid={formValid}
                        formErrors={errors}
                      />
                    </FormControl>
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol>
                    <FormControl fullWidth>
                      <SecurityQuestionSelect
                        id="question"
                        placeholderText="Security Question"
                        showOnlyFormError
                        values={values}
                        handleChange={(id, value) => setFieldValue(id, value)}
                        formValid={formValid}
                        formErrors={errors}
                      />
                    </FormControl>
                  </FormCol>
                  <FormCol>
                    <FormControl fullWidth>
                      <FormTextField
                        id="answer"
                        placeholderText="Answer"
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        formValid={formValid}
                        formErrors={errors}
                      />
                    </FormControl>
                  </FormCol>
                </FormRow>
                <FormRow>
                  <FormCol style={{ margin: 'auto' }}>
                    <p className="input-failed-value">{errorMessage}</p>
                    <p className="input-success-value">{successMessage}</p>
                  </FormCol>
                  <RightCol md={12}>
                    <Button
                      type="secondary"
                      width="206"
                      shadow={false}
                      button
                      disabled={!isValid}
                      onClick={handleSubmit}
                    >
                      Save Changes
                    </Button>
                  </RightCol>
                </FormRow>
              </div>
            </>
          )}
        </Col>
      </Row>
    </ToolboxSettingsSectionLayout>
  );
};

export default withFormik({
  validationSchema: passwordSchema,
  mapPropsToValues: ({ userInfo }) => userInfo,
  handleSubmit: (values, { props, setErrors }) => {
    props.onSave(values);
  },
})(SecuritySettingsContent);
