import React from 'react';
import { StyledSettingsMenu } from './styles';
import HyperLink from 'components/Core/HyperLink';
import BookmarksIcon from 'components/Brand/Icons/Toolbox/BookmarksIcon';
import SettingsIcon from 'components/Brand/Icons/Toolbox/SettingsIcon';
import SecurityIcon from 'components/Brand/Icons/Toolbox/SecurityIcon';
import { Col, Row } from 'components/Core/Grid';

const SettingsMenuItem = ({ children, selected }) => (
  <h6 className={selected === children.text ? 'selected' : ''}>
    <HyperLink href={children.url}>
      <Row>
        <Col md={5} className="settings-menu-icon">
          {(children.icon && children.icon()) || <div />}
        </Col>
        <Col md={19} className="settings-menu-text">
          {children.text}
        </Col>
      </Row>
    </HyperLink>
  </h6>
);

const SettingsMenu = ({
  items = [
    {
      text: 'Account Settings',
      url: '/toolbox/settings',
      icon: () => <SettingsIcon width="28" height="23" fill="white" />,
    },
    {
      text: 'Account Security',
      url: '/toolbox/security',
      visible: (u) => !u?.isSocial,
      icon: () => <SecurityIcon width="28" height="23" fill="white" />,
    },
    // { text: 'Alert Settings', url: '../alert-settings' },
    // { text: 'Invite Friends', url: '../invite' },
    {
      text: 'My Bookmarks',
      url: '/toolbox/bookmarks',
      icon: () => <BookmarksIcon width="28" height="23" />,
    },
  ],
  selected,
  userInfo = {},
}) => (
  <StyledSettingsMenu>
    {items
      .filter((x) => !x.visible || x.visible(userInfo))
      .map((item) => (
        <SettingsMenuItem selected={selected}>{item}</SettingsMenuItem>
      ))}
  </StyledSettingsMenu>
);

export default SettingsMenu;
